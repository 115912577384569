showGrammatica = function () {
    closeAllMenu();
    openWindow(1);

    $(".grammatica-items").html('<div class="lds-ring loading-grammatica"><div></div><div></div><div></div><div></div></div>');

    let request = $.ajax({
        url: '/grammatica/'+activeMethode,
        method: "GET",
        headers: {'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')},
        cache: false,
        async: true,
        contentType: false,
        processData: false
    });
    request.done(function (response) {
        if (response.status == "ok") {
            $(".loading-grammatica").remove();
            for (item of response.items) {
                let strItem = '';
                strItem = '<button data-id="grammatica-'+item.id+'" type="button" class="collapsible">' + item.titel + '</button>' +
                    '<div class="content" data-id="grammatica-'+item.id+'">' + item.inhoud + '<div style="display:none">' + item.titel + '</div></div>';

                $(".grammatica-items").append(strItem);
            }

        } else {
            toastr["error"]("Fout<br>" + response.message)
        }
    });
    request.fail(function(e) {
        toastr["error"]("Fout<br>" + e.statusText)
    });
}

FilterGrammatica = function() {
    let filterOp = $("#zoekenGrammatica").val();

    if (filterOp != "") {
        $(".grammatica-items .content").hide();
        $(".grammatica-items .content").each( function () {
            let current = $(this).data('id');
            if ($(this).text().toUpperCase().indexOf(filterOp.toUpperCase()) > -1) {
                $(`[data-id='${current}']`).hide();
                $(`button[data-id='${current}']`).show();
            } else {
                $(`[data-id='${current}']`).hide();
                $(`button[data-id='${current}']`).hide();

            }
        });
    } else {
        $(".grammatica-items button").show();
    }
}
