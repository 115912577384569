let taalDropdown;
let woordentrainerCounter = 0;
let woordentrainerLeft = 0;

$( document ).ready(function() {
    taalDropdown = $('#taal').prettyDropdown({
        width: '300',
        height: 40,
        classic: true,
        selectedMarker: ''
    });
    $('#taal').on('change', function() {

        loadWoorden();

    });
});

showWoordentrainer = function () {
    closeAllMenu();
    openWindow(6);
    loadWoorden();
}

let woordentrainer  = [];

loadWoorden = function () {
    $(".woordentrainer-content").html('<div class="lds-ring loading-grammatica"><div></div><div></div><div></div><div></div></div>');

    let request = $.ajax({
        url: '/woordentrainer/'+activeMethode+'/'+activeModule,
        method: "GET",
        headers: {'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')},
        cache: false,
        async: true,
        contentType: false,
        processData: false
    });
    request.done(function (response) {
        if (response.status == "ok") {
            $(".loading-grammatica").remove();
            woordentrainer = [];

            if (response.items.length == 0) {
                $(".woordentrainer-content").html('Er zijn helaas geen woorden gevonden om mee te oefenen.');
            } else {
                $(".woordentrainer-content").html('<div class="woordentrainer-uitleg">Je krijg straks steeds een aantal woorden die herhaald worden, hiermee kun je oefenen met de vertaling. Af en toe moet je een vertaling invoeren zodat je kunt controleren of je het woord ook écht kent.<br><br>Druk op <b>Volgende</b> om te starten!</div>');
                let id = 1;
                for (item of response.items) {
                    woordentrainer.push({ id:id, nl_woord: item.nl_woord, vertaling: item.vertaling, done: false, nrGetoond: 0, order:0 });
                    id++;
                }
                woordentrainerLeft = response.items.length;

                $(".woordentrainer-voortgang").html("0/"+woordentrainer.length)
            }
        } else {
            toastr["error"]("Fout<br>" + response.message)
        }
    });
    request.fail(function(e) {
        toastr["error"]("Fout<br>" + e.statusText)
    });
}

$(document).on('click', '.woordentrainer-volgende', function() {
    woordentrainerNext();
});
$(document).on('click', '.woordentrainer-vertaling-knop', function() {
    $(this).fadeOut(500);
    $('.woordentrainer-vertaling').fadeIn(600);
})
$(document).on('click', '.woordentrainer-opnieuw-knop', function() {
    loadWoorden();
})

let geefVertaling = false;
let juisteVertaling = '';
let woordId = 0;

woordentrainerNext = function() {
    woordentrainer = multiSort(woordentrainer, {order: 'desc'});

    if (geefVertaling) {
        let ingevoerdeVertaling  = $(".woordentrainer-vertaling-invulveld").val();

        if (ingevoerdeVertaling != '') {
            if (ingevoerdeVertaling == juisteVertaling) {
                woordentrainerLeft--;
                $(".woordentrainer-vertaling-invulveld").addClass('goed');
                $(".woordentrainer-vertaling-wrapper .input-icon.goed ").show();
                $(".woordentrainer-vertaling-invulveld").attr("readonly", "readonly");
                $(".woordentrainer-voortgang").html((woordentrainer.length - woordentrainerLeft) + "/" + woordentrainer.length)

                for (let a = 0; a < woordentrainer.length; a++) {
                    if (woordentrainer[a].id == woordId) {
                        woordentrainer[a].done = true;
                    }
                }
            } else {
                $(".woordentrainer-vertaling-invulveld").addClass('fout');
                $(".woordentrainer-vertaling-wrapper .input-icon.fout ").show();
                $(".woordentrainer-vertaling-invulveld").attr("readonly", "readonly");
            }
            geefVertaling = false;
            juisteVertaling = '';
            woordId = 0;
            woordentrainerCounter = 0;
            console.log(woordentrainer);
        } else {
            toastr["error"]("Fout<br>Vul eerst de vertaling in.")
            $("woordentrainer-vertaling-invulveld").focus();
            return false;
        }
    } else {

        let strHTML = '';

        if (woordentrainerLeft <= 0) {
            strHTML += '<div class="woordentrainer-taal">Klaar!</div>';
            strHTML += '<div class="woordentrainer-opnieuw-knop">Nog een keer <i class="far fa-redo"></i></div>';
            $(".woordentrainer-content").html(strHTML);
        } else {
            if (woordentrainerCounter >= 5 || woordentrainerCounter >= woordentrainerLeft) {
                woordentrainerCounter = 0;

                let tempWoordentrainer = [];

                for (let a = 0; a < woordentrainer.length; a++) {
                    let woord = woordentrainer[a];


                    if (woord.done == false && woord.nrGetoond > 0) {
                        if ($("#taal").val() == 1) {
                            strHTML += '<div class="woordentrainer-taal">Engels:</div>';
                            strHTML += '<div class="woordentrainer-woord">' + woord.vertaling + '</div>';
                            strHTML += '<div class="woordentrainer-vertaal">Nederlands:</div>';
                            strHTML += '<div class="woordentrainer-vertaling-wrapper"><input type="text" name="vertaling" class="woordentrainer-vertaling-invulveld" placeholder="vul het woord in..."><label for="vertaling" class="far fa-check input-icon goed"></label><label for="vertaling" class="far fa-times input-icon fout"></label></div>';
                            juisteVertaling = woord.nl_woord;
                        } else {
                            strHTML += '<div class="woordentrainer-taal">Nederlands:</div>';
                            strHTML += '<div class="woordentrainer-woord">' + woord.nl_woord + '</div>';
                            strHTML += '<div class="woordentrainer-vertaal">Engels:</div>';
                            strHTML += '<div class="woordentrainer-vertaling-wrapper"><input type="text" name="vertaling" class="woordentrainer-vertaling-invulveld" placeholder="vul het woord in..."><label for="vertaling" class="far fa-check input-icon goed"></label><label for="vertaling" class="far fa-times input-icon fout"></label></div>';
                            juisteVertaling = woord.vertaling;
                        }
                        woordId = woord.id;
                        geefVertaling = true;

                        break;
                    }

                }
            } else {
                for (let a = 0; a < woordentrainer.length; a++) {
                    let woord = woordentrainer[a];
                    woord.order = Math.floor(Math.random() * 1000)
                }

                let woord;
                for (let a = 0; a < woordentrainer.length; a++) {
                    woord = woordentrainer[a];

                    if (woord.done == false) {
                        woord.nrGetoond++;
                        break;
                    }
                }

                if ($("#taal").val() == 1) {
                    strHTML += '<div class="woordentrainer-taal">Engels:</div>';
                    strHTML += '<div class="woordentrainer-woord">' + woord.vertaling + '</div>';
                    strHTML += '<div class="woordentrainer-vertaal">Nederlands:</div>';
                    strHTML += '<div style="position: relative">'
                    strHTML += '<div class="woordentrainer-vertaling" style="display:none">' + woord.nl_woord + '</div>';
                    strHTML += '<div class="woordentrainer-vertaling-knop">Laat het woord zien</div>';
                    strHTML += '</div>'
                } else {
                    strHTML += '<div class="woordentrainer-taal">Nederlands:</div>';
                    strHTML += '<div class="woordentrainer-woord">' + woord.nl_woord + '</div>';
                    strHTML += '<div class="woordentrainer-vertaal">Engels:</div>';
                    strHTML += '<div style="position: relative">'
                    strHTML += '<div class="woordentrainer-vertaling" style="display:none">' + woord.vertaling + '</div>';
                    strHTML += '<div class="woordentrainer-vertaling-knop">Laat het woord zien</div>';
                    strHTML += '</div>'
                }

            }
            woordentrainerCounter++;
            $(".woordentrainer-content").html(strHTML);
        }
    }
}

