let lesmateriaalDropdown;

$( document ).ready(function() {
    if ($("#lesmateriaal").length > 0) {
        $("#lesmateriaal").html(creatMethodeDropdown(methodes));

        lesmateriaalDropdown = $('#lesmateriaal').prettyDropdown({
            width: '93%',
            height: 38,
            classic: true,
            selectedMarker: ''
        });

        $('.depth-3').parent().addClass('bold');
        $('.depth-2').parent().addClass('highlight');

        $('#lesmateriaal').on('change', function () {
            console.log("Load module: ", this.value);
            if (this.value != "") {
                location.href = '/module/' + this.value;
                $(this)[0].selectedIndex = 0;
                lesmateriaalDropdown.refresh();
            }
        });
    }
});

function creatMethodeDropdown(data, depth = 0) {
    let result = '';
    let spacer = '';
    let selected = '';
    let setclass = '';

    if (depth == 0) {
        result = '<option value="">Kies je lesmateriaal</option>';
    }

    for(let a = 0; a < depth; a++) { spacer += '&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;'; }

    $.each(data, function(i, val){
        selected = '';
        if (val.id == activeModule && depth == 4) {
            selected = "selected";
        }

        if (val.children && val.children.length > 0) {
            //setclass = "option--bold";
        }

        let menuText = val.text;

        if (depth < 3) {
            menuText = menuText.toUpperCase();
        }

        if (!isNaN(val.id)) {
            result += '<option value="'+val.id+'" '+selected+' data-prefix="<span style=\'margin-left:'+(depth*20)+'px\'></span><i class=\'fa-module fa-regular fa-file\'></i>">' + menuText + '</option>';
        } else {
            result += '<option value="0" data-prefix="<span class=\'depth-'+depth+'\' style=\'margin-left:'+(depth*20)+'px\'></span>">' + menuText + '</option>';
        }

        if (val.children && val.children.length > 0) {
            result += creatMethodeDropdown(val.children, depth+1);
        }
    });

    return result;
}


toggleLeftMenu = function() {
    let menu = $(".nav--left");
    let overlay = $(".overlay");

    if (menu.hasClass("transform-right")) {
        menu.removeClass("transform-right");
        overlay.removeClass("overlay__visible")
    } else {
        menu.addClass("transform-right");
        overlay.addClass("overlay__visible")
    }
}

toggleRightMenu = function() {
    let menu = $(".nav--right");
    let overlay = $(".overlay");

    if (menu.hasClass("transform-right")) {
        menu.removeClass("transform-right");
        overlay.removeClass("overlay__visible")
    } else {
        menu.addClass("transform-right");
        overlay.addClass("overlay__visible")
    }
}

closeAllMenu = function() {
    $(".nav--left").removeClass("transform-right");
    $(".nav--right").removeClass("transform-right");
    $(".overlay").removeClass("overlay__visible")
}


$(document).on('click','.nav-btn--left, .nav__close--left', function() {
    toggleLeftMenu();
});
$(document).on('click','.nav-btn--right, .nav__close--right', function() {
    toggleRightMenu();
});
$(document).on('click','.overlay', function() {
    closeWindow('6');
    closeAllMenu();
});
