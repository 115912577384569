'use strict';

const contextPanel = document.querySelector('.context-panel');
const exercisePanel = document.querySelector('.exercise-panel');
if (exercisePanel) {
    exercisePanel.addEventListener('click', () => {
        contextPanel.classList.remove('panel-active');
        exercisePanel.classList.add('panel-active');
    });
}

if (contextPanel) {
    contextPanel.addEventListener('click', () => {
        exercisePanel.classList.remove('panel-active');
        contextPanel.classList.add('panel-active');
    });
}
