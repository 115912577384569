$( window ).resize(function() {
    resizePanel();
});

resizePanel = function() {
    if ($(".main").length) {
        let windowHeight = $(window).height();
        let headerHeight = $(".header").outerHeight();
        let footerHeight = $(".footer").outerHeight();
        let bodyPadding = $("body").outerHeight() - $("body").height();
        let panelPadding = $(".panel-active").outerHeight() - $(".panel-active").height();
        let mainPadding = $(".main").outerHeight() - $(".main").height();
        let mainMargin  = parseInt($(".main").css("marginTop").replace('px', '')) + parseInt($(".main").css("marginBottom").replace('px', ''))
        let newHeight = windowHeight - headerHeight - footerHeight - bodyPadding - panelPadding - mainMargin - 10

        $(".panel-wrapper").css("height", newHeight);

        $(".exercise-panel__bottom").css("height",  $(".exercise-panel .panel-wrapper").outerHeight() - $(".exercise-panel__top").outerHeight() - $(".exercise-panel__middle").outerHeight() - $(".exercise-panel__feedback").outerHeight());

    }
}

