require('./bootstrap');
require('./main');
require('./berichten');
require('./meldpunt');
require('./grammatica');
require('./pronunciation');
require('./dictionary');
require('./woordentrainer');
require('./menu');
require('./exercises');
require('./jquery.connections');

import select from './select';
import dropdown from './dropdown';
import panel from './panel';
import modals from './modals';
import layout from  './layout';
