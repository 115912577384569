$(document).on('click', '.meldpunt-verzenden ', function() {

    let form = $("#meldpuntform");
    $("#bericht-methode-id").val(activeMethode);
    $("#bericht-module-id").val(activeModule);
    $("#bericht-lesmateriaal-id").val(activeLesmateriaalId);
    let data = new FormData(form[0]);

    if ($('#meldpunt-formulier-inhoud').val() != '') {
        $(".meldpunt-verzenden").hide();
        let request = $.ajax({
            url: '/bericht/save',
            method: 'POST',
            headers: {'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')},
            enctype: 'multipart/form-data',
            data: data,
            cache: false,
            async: true,
            contentType: false,
            processData: false
        });
        request.done(function (response) {
            if (response.status == "ok") {
                $('#meldpunt-formulier-inhoud').val('')
                $(".meldpunt-verzenden").show();
                closeWindow(3);
                toastr["success"]("Gelukt!<br>" + response.message)
            } else {
                $(".meldpunt-verzenden").show();
                toastr["error"]("Fout<br>" + response.message)
            }
        });
        request.fail(function (e) {
            toastr["error"]("Fout<br>" + e.statusText)
        });
    }
});

$(document).on('click', '.collapsible', function() {
    let current = $(this).data('id');

    $(`.content[data-id='${current}']`).toggle();

});
