

//STELLINGEN

$(document).on("click", ".stelling span", function() {
    if ($(this).hasClass('active') ) {
        $(this).removeClass('active');
    } else {
        $(this).parent().find('span').removeClass('active');
        $(this).addClass('active');
    }
});



//VUL DE ONTBREKENDE WOORDEN IN, TEXTAREA (CONTENTEDITABLE DIV)
$('body').on('focus', '[contenteditable]', function() {

    const $this = $(this);
    $this.data('before', $this.html());
    $this.html(cleanHTML($this.html()));
}).on('blur paste ', '[contenteditable]', function() {
    const $this = $(this);
    if ($this.data('before') !== $this.html()) {
        $this.data('before', $this.html());
        $this.trigger('change');
    }
    $this.html(cleanHTML($this.html()));

});




