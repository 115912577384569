let activeBericht = 0;
let activeBerichtData;
let berichtDropdown;

$( document ).ready(function() {
    berichtDropdown = $('#bericht-formulier-aan').prettyDropdown({
        width: '99%',
        height: 40,
        classic: true,
        selectedMarker: ''
    });
});

showBerichten = function() {
    closeAllMenu();
    openWindow(2)

    $('.bericht-row').remove();

    $("#bericht").hide();
    $("#berichten").show();
    $("#bericht-formulier").hide();

    $(".bericht-nieuw").show();

    $(".bericht-terug").hide();
    $(".bericht-antwoord").hide();


    activeBericht = 0;

    let request = $.ajax({
        url: '/bericht',
        method: "GET",
        headers: {'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')},
        cache: false,
        async: true,
        contentType: false,
        processData: false
    });
    request.done(function (response) {
        if (response.status == "ok") {

            for (bericht of response.berichten) {
                let strTableRow = '';
                let link = '';
                let gelezen = 'bold';
                if (bericht.gelezen == 1) {
                    gelezen  = '';
                }
                strTableRow = '<tr class="bericht-row '+gelezen+'" data-id="'+bericht.id+'" id="bericht-'+bericht.id+'">' +
                    '<td><div class="ellipsis show-bericht">' + bericht.titel + '</div></td>' +
                    '<td><div class="show-bericht"></div>' + bericht.datum + '</div></td>' +
                    '<td class="text-center"><div class="delete-bericht"><i class="far fa-trash-alt"></i></div></td>' +
                    '</tr>';



                $('#berichten tr:last').after(strTableRow);
            }

        } else {
            toastr["error"]("Fout<br>" + response.message)
        }
    });
    request.fail(function(e) {
        toastr["error"]("Fout<br>" + e.statusText)
    });
}

$(document).on('click', '.delete-bericht', function() {

    Swal.fire({
        title: 'Weet je zeker dat je dit bericht wilt verwijderen?',
        text: 'Deze actie kan niet ongedaan worden gemaakt!',
        icon: 'warning',
        showCancelButton: true,
        cancelButtonText: 'Annuleren',
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Ja, verwijder bericht',
        showClass : { popup: '', backdrop: 'swal2-backdrop-show', icon: '' }
    }).then((result) => {
        if (result.isConfirmed) {
            let berichtId = $(this).closest('tr').data('id');

            let request = $.ajax({
                url: '/bericht/'+berichtId,
                method: 'DELETE',
                headers: {'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')},
                cache: false,
                async: true,
                contentType: false,
                processData: false
            });
            request.done(function (response) {
                if (response.status == 'ok') {
                    $('#bericht-'+berichtId).hide(500);
                }
            });
            request.fail(function(e) {
                toastr['error']('Fout<br>' + e.statusText)
            });

        }
    });
});

$(document).on('click', '.bericht-antwoord, .bericht-nieuw', function() {

    $("#bericht").hide();
    $("#berichten").hide();
    $("#bericht-formulier").show();

    $(".bericht-nieuw").hide();
    $(".bericht-terug").show();
    $(".bericht-antwoord").hide();

    $(".bericht-verzenden").show();

    if (activeBericht == 0) {
        $("#bericht-formulier-aan").val('');
        $("#bericht-formulier-titel").val('');
        $("#bericht-formulier-inhoud").val('');
    } else {
        $("#bericht-formulier-aan").val(activeBerichtData.afzender_id).change();
        berichtDropdown.refresh();
        console.log($("#bericht-formulier-aan").val());

        $("#bericht-formulier-titel").val('RE:' + activeBerichtData.titel);
        $("#bericht-formulier-inhoud").val('\n\n\nOriginele bericht:\n' + activeBerichtData.inhoud);

    }
});

$(document).on('click', '.bericht-terug', function() {
    activeBericht = 0;
    $("#bericht_onderwerp").html('');
    $("#bericht_afzender").html('');
    $("#bericht_datum").html('');
    $("#bericht_inhoud").html('');

    $("#bericht").hide();
    $("#berichten").show();
    $("#bericht-formulier").hide();

    $(".bericht-nieuw").show();
    $(".bericht-terug").hide();
    $(".bericht-antwoord").hide();

    $("#bericht-formulier-aan").val('');
    $("#bericht-formulier-titel").val('');
    $("#bericht-formulier-inhoud").val('');
});

$(document).on('click', '.bericht-verzenden', function() {
    $(this).hide();
    let form = $("#berichtform");
    let data = new FormData(form[0]);

    let request = $.ajax({
        url: '/bericht/save',
        method: 'POST',
        headers: {'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')},
        enctype: 'multipart/form-data',
        data: data,
        cache: false,
        async: true,
        contentType: false,
        processData: false
    });
    request.done(function (response) {
        if (response.status == "ok") {
            $( ".bericht-terug" ).trigger( 'click');
            toastr["success"]("Gelukt!<br>" + response.message)
        } else {
            $(".bericht-verzenden").show();
            toastr["error"]("Fout!<br>" + response.message)
        }
    });
    request.fail(function(e) {
        toastr["error"]("Error<br>" + e.statusText)
    });
});

$(document).on('click', '.show-bericht', function() {
    $("#berichten").hide();

    $(".bericht-nieuw").hide();
    $(".bericht-terug").show();
    $(".bericht-antwoord").show();


    let berichtId = $(this).closest('tr').data('id');
    activeBericht = berichtId;

    $(this).closest('tr').removeClass("bold");

    if ($(".bericht-row.bold").length == 0) {

    }
    $(".ongelezenBerichten").html($(".bericht-row.bold").length)

    let request = $.ajax({
        url: '/bericht/'+berichtId,
        method: "GET",
        headers: {'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')},
        cache: false,
        async: true,
        contentType: false,
        processData: false
    });
    request.done(function (response) {
        if (response.status == "ok") {
            $("#bericht").show();
            activeBerichtData = response.bericht;
            let bericht = response.bericht;
            $("#bericht_onderwerp").html(bericht.titel);
            $("#bericht_afzender").html(bericht.afzender);
            $("#bericht_datum").html(bericht.datum);
            $("#bericht_inhoud").html(bericht.inhoud);

        } else {
            $("#berichten").show();
            toastr["error"]("Fout<br>" + response.message)
        }
    });
    request.fail(function(e) {
        $("#berichten").show();
        toastr["error"]("Fout<br>" + e.statusText)
    });

});
