const metaTag = document.createElement("meta");
metaTag.name = "viewport";
metaTag.content = "user-scalable=0";
document.getElementsByTagName("head")[0].appendChild(metaTag);

const active = document.getElementsByClassName("window");

window.createWindow = function(id) {
    let windowID = document.getElementById("window" + id);
    let headerID = windowID.firstElementChild;
    headerID.id = "window" + id + "header";

    if (id != 5) {
        let createCloseButton = document.createElement("b");
        createCloseButton.id = "closeButton" + id;
        createCloseButton.innerHTML = "×";
        document.getElementById("window" + id + "header").appendChild(createCloseButton);


        document.getElementById("closeButton" + id).onclick = function () {
            if (id == 5) {
                $(".exercise-panel__bottom__disabler").hide();
                hideNakijkenKleuren();
            }
            if (id == 6) {
                $(".overlay").removeClass('overlay__visible');
            }
            fadeOut(windowID);
        };
    }
    dragElement(windowID);
}

window.closeWindow = function (id) {
    let windowID = document.getElementById("window" + id);
    fadeOut(windowID);
}

window.openWindow = function (id) {
    let windowID = document.getElementById("window" + id);
    if (id == 6) {
        $(".overlay").addClass('overlay__visible');
    }
    if (windowID.style.display === "initial") {
        activeWindow(windowID);
    } else {
        windowID.style = "position: absolute;";
        windowID.style = "top: 20px;";
        fadeIn(windowID);
    }
};

function dragElement(elmnt) {
    var pos1 = 0,
        pos2 = 0,
        pos3 = 0,
        pos4 = 0;
    if ("ontouchstart" in document.documentElement) {
        var pos1touch = 0,
            pos2touch = 0,
            pos3touch = 0,
            pos4touch = 0;
    }
    if (document.getElementById(elmnt.id + "header")) {
        document.getElementById(elmnt.id + "header").onmousedown = dragMouseDown;
        document.getElementById(elmnt.id + "header").ontouchstart = dragMouseDown;
    }

    function dragMouseDown(e) {
        if (!"ontouchstart" in document.documentElement) {
            e.preventDefault();
        }
        pos3 = e.clientX;
        pos4 = e.clientY;
        if ("ontouchstart" in document.documentElement) {
            try {
                pos3touch = e.touches[0].clientX;
                pos4touch = e.touches[0].clientY;
            } catch(error) {}
        }
        document.onmouseup = closeDragElement;
        document.onmousemove = elementDrag;
        document.ontouchend = closeDragElement;
        document.ontouchmove = elementDrag;
        activeWindow(document.getElementById(elmnt.id));
    }

    function elementDrag(e) {
        e.preventDefault();
        if ("ontouchstart" in document.documentElement) {
            pos1touch = pos3touch - e.touches[0].clientX;
            pos2touch = pos4touch - e.touches[0].clientY;
            pos3touch = e.touches[0].clientX;
            pos4touch = e.touches[0].clientY;
            elmnt.style.top = (elmnt.offsetTop - pos2touch) + "px";
            elmnt.style.left = (elmnt.offsetLeft - pos1touch) + "px";
        } else {
            pos1 = pos3 - e.clientX;
            pos2 = pos4 - e.clientY;
            pos3 = e.clientX;
            pos4 = e.clientY;
            if ((elmnt.offsetTop - pos2) > 20) {
                elmnt.style.top = (elmnt.offsetTop - pos2) + "px";
            }
            if ((elmnt.offsetLeft - pos1))  { // - (elmnt.offsetWidth/2) > 20) {
                elmnt.style.left = (elmnt.offsetLeft - pos1) + "px";
            }
        }
    }

    function closeDragElement() {
        document.onmouseup = null;
        document.onmousemove = null;
        document.ontouchend = null;
        document.ontouchmove = null;
    }
}

function fadeIn(elmnt) {
    elmnt.style.opacity = 0;
    elmnt.style.display = "initial";
    if (elmnt.classList.contains("fade")) {
        var opacity = 0;
        var timer = setInterval(function () {
            opacity += 30 / 70;
            if (opacity >= 1) {
                clearInterval(timer);
                opacity = 1;
            }
            elmnt.style.opacity = opacity;
            activeWindow(elmnt);
        }, 50);
    } else {
        elmnt.style.opacity = "1";
        activeWindow(elmnt);
    }
}

function fadeOut(elmnt) {
    if (elmnt.classList.contains("fade")) {
        var opacity = 1;
        var timer = setInterval(function () {
            opacity -= 30 / 70;
            if (opacity <= 0) {
                clearInterval(timer);
                opacity = 0;
                elmnt.style.display = "none";
            }
            elmnt.style.opacity = opacity;
        }, 50);
    } else {
        elmnt.style.display = "none";
        activeWindow(elmnt);
    }
}

function activeWindow(elmnt) {
    /*
    for (let i = active.length - 1; i > -1; i--) {
        active[i].classList.remove("windowActive");
        elmnt.className += " windowActive";
    }*/
}
