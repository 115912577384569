showPronunciation = function () {
    closeAllMenu();
    openWindow(9);

    $(".pronunciation-items").html('<div class="lds-ring loading-pronunciation"><div></div><div></div><div></div><div></div></div>');

    let request = $.ajax({
        url: '/pronunciation/'+activeMethode,
        method: "GET",
        headers: {'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')},
        cache: false,
        async: true,
        contentType: false,
        processData: false
    });
    request.done(function (response) {
        gtag('event', 'page_view', {
            'page_title': methodeTitel + ' - Pronunciation',
            'page_path': '/pronunciation/'+activeMethode
        });

        if (response.status == "ok") {
            console.log(response);
            $(".loading-pronunciation").remove();
            for (item of response.items) {
                let strItem = '';
                strItem = '<button data-id="pronunciation-'+item.id+'" type="button" class="collapsible">' + item.titel + '</button>';
                strItem += '<div class="content" data-id="pronunciation-'+item.id+'">' + item.inhoud + '<div style="display:none">' + item.titel + '</div>';
                for (audio of item.audio) {
                    console.log(audio);


                    strItem += '<p><b>'+audio.titel+'</b><br><audio controls><source src="'+audio.url+'" type="audio/mpeg"></audio></p>';

                }

                strItem += '</div>';



                $(".pronunciation-items").append(strItem);
            }

        } else {
            toastr["error"]("Fout<br>" + response.message)
        }
    });
    request.fail(function(e) {
        toastr["error"]("Fout<br>" + e.statusText)
    });
}

FilterPronunciation = function() {
    let filterOp = $("#zoekenPronunciation").val();

    if (filterOp != "") {
        $(".pronunciation-items .content").hide();
        $(".pronunciation-items .content").each( function () {
            let current = $(this).data('id');
            if ($(this).text().toUpperCase().indexOf(filterOp.toUpperCase()) > -1) {
                $(`[data-id='${current}']`).hide();
                $(`button[data-id='${current}']`).show();
            } else {
                $(`[data-id='${current}']`).hide();
                $(`button[data-id='${current}']`).hide();

            }
        });
    } else {
        $(".pronunciation-items button").show();
    }
}
