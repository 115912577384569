showDictionary = function () {
    closeAllMenu();
    openWindow(8);

    $(".dictionary-items").html('<div class="lds-ring loading-dictionary"><div></div><div></div><div></div><div></div></div>');

    let request = $.ajax({
        url: '/dictionary/'+activeMethode,
        method: "GET",
        headers: {'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')},
        cache: false,
        async: true,
        contentType: false,
        processData: false
    });
    request.done(function (response) {
        gtag('event', 'page_view', {
            'page_title': methodeTitel + ' - Dictionary',
            'page_path': '/dictionary/'+activeMethode
        });

        if (response.status == "ok") {
            $(".loading-dictionary").remove();
            let strHTML = '<table>';
            for (item of response.items) {
                let strItem = '';
                strItem = '<tr data-letter="' + item.letter + '">' +
                    '<td>' + item.woord + '</td>' +
                        '<td>'+item.vertaling+'</td>'+
                            '<td>'+item.zin+'</td>' +
                    '</tr>';

                strHTML += strItem;
            }
            strHTML += '</table>';
            $(".dictionary-items").append(strHTML);
        } else {
            toastr["error"]("Fout<br>" + response.message)
        }
    });
    request.fail(function(e) {
        toastr["error"]("Fout<br>" + e.statusText)
    });
}
$(document).on('click', '.btn-dictionary', function() {
    let letter = $(this).data('letter');

    if ($("tr[data-letter='" + letter +"']:first").length) {
        $("#window8 .mainWindow").animate({scrollTop: $("tr[data-letter='" + letter + "']:first").position().top}, 200);
    }

});

